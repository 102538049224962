import React, { Fragment, useEffect, useState } from "react";
import useSound from "use-sound";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { quizSound, showToast } from "Redux/General/Actions";
import { updateTotalScore, updateGameScore, timerCompleteForPursuit, schoolClassTestID } from "Redux/Auth/Actions";
// COMPONENTS
import LazyImage from "Components/Commons/LazyImage/LazyImage";
import PursuitHowToPlay from "Components/Economics/Pursuit/Modal/HowToPlay";
import RapidFireHowToPlay from "Components/Economics/RapidFire/Modal/HowToPlay";
// STYLES
import { PageWrapper, GridContainer, ScoreboardWrapper, ProgressWrapper } from "./Dashboard.style";
// HELPERS
import { random, Api, URL_PURSUIT, ECONOMICS_MODULE_ID, URL_RAPID_FIRE, IMAGES } from "Helpers";
// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";

const tooltipStyles = { tooltip: { padding: "8px 18px", borderRadius: "10px", marginBottom: "22px", fontSize: "14px", backgroundColor: "white", color: "black" } };

const sectionConst = [
	{ name: "Rapid Fire", image: IMAGES.ECONOMICS.RAPID_FIRE_CARD, value: 0, subTitle: "RAPID FIRE", url: URL_RAPID_FIRE, id: ECONOMICS_MODULE_ID.RAPID_FIRE, isLive: true, isTestAvailable: false },
	{ name: "Pursuit", image: IMAGES.ECONOMICS.PURSUIT_CARD, value: 0, subTitle: "PURSUIT", url: URL_PURSUIT, id: ECONOMICS_MODULE_ID.PURSUIT, isLive: true, isTestAvailable: false },
];

const Dashboard = () => {
	const dispatch = useDispatch();
	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const { gamesStatus } = useSelector((state) => state.GamesData);
	const subjects = useSelector((state) => state.Auth.subjectAccessList);

	const subjectId = subjects?.filter((item) => item.subject === "Economics")[0].subjectId;

	const [sections, setSections] = useState([...sectionConst]);
	const [scoreboard, setScoreboard] = useState([]);
	const [isStudent, setStudent] = useState(false);
	const [testAvailable, setTestStatus] = useState(false);
	const [status, setStatus] = useState(0);
	const [loading, setLoading] = useState(true);
	const [pursuitDetails, setPursuitDetails] = useState({});
	const [schoolClassTestId, setSchoolClassTestId] = useState("");
	const [pursuitHowToPlay, setPursuitHowToPlay] = useState({ open: false, subTitle: "", url: null, id: null });
	const [rapidFireHowToPlay, setRapidFireHowToPlay] = useState({ open: false, subTitle: "", url: null, id: null });

	useEffect(() => {
		dispatch(quizSound(true));

		setTimeout(() => {
			let newValues = [...sections];

			newValues.forEach((section) => {
				if (!section.isLive) section.value = random(30, 100);
			});

			setSections(newValues);
		}, 1500);
	}, []); // eslint-disable-line

	useEffect(() => {
		if (Object.keys(gamesStatus).length) getStatus();
	}, [gamesStatus]); // eslint-disable-line

	const getStatus = async () => {
		try {
			setLoading(true);

			let response = await new Api().get("general/studentStatus", { params: { subjectId: subjectId } });

			const percentValue = (sub, total) => ((sub * 100) / total).toFixed(0);

			if (response.data.pursuit && response.data.pursuit?.isPursuitGameComplete && response.data.pursuit.isPursuitGameComplete) dispatch(timerCompleteForPursuit(false));

			// SET PROGRESS
			const newValues = [...sections];

			newValues.forEach((section) => {
				switch (section.id) {
					case ECONOMICS_MODULE_ID.PURSUIT:
						if (response?.data?.pursuit?.isPursuitGameComplete && response.data.pursuit.isPursuitGameComplete) section.value = parseInt(100);
						else {
							let value = 0;

							if (
								response?.data?.pursuit?.isPursuitRapidFireComplete &&
								response.data.pursuit.isPursuitRapidFireComplete &&
								!response?.data?.pursuit?.isPursuitChaserComplete &&
								!response.data.pursuit.isPursuitChaserComplete
							) {
								section.value = 50;
							} else if (
								!response?.data?.pursuit?.isPursuitRapidFireComplete &&
								!response.data.pursuit.isPursuitRapidFireComplete &&
								response?.data?.pursuit?.isPursuitChaserComplete &&
								response.data.pursuit.isPursuitChaserComplete
							) {
								section.value = 50;
							} else {
								section.value = value;
							}
						}

						if (gamesStatus?.isPursuitActive) section.isTestAvailable = true;

						break;

					case ECONOMICS_MODULE_ID.RAPID_FIRE:
						if (response?.data?.rapidFireEconomics?.isRapidFireEconomicsComplete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.rapidFireEconomics?.totalRightAnswerRapidFireEconomics, response?.data?.rapidFireEconomics?.totalQuestionRapidFireEconomics);
							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isRapidFireEconomicsActive) section.isTestAvailable = true;

						break;

					default:
						section.value = 0;
						break;
				}
			});

			dispatch(updateTotalScore(response.data.total));
			dispatch(updateGameScore(response.data.pursuit?.pursuitBranchScore));

			setSections(newValues);
			setScoreboard(response.data.scoreDashBoardList);
			setTestStatus(response?.data?.isLiveTestAvailable);
			setStudent(response?.data?.isStudentLogin);
			dispatch(schoolClassTestID(response?.data?.schoolClassTestId));
			setPursuitDetails(response.data.pursuit);
			setSchoolClassTestId(response?.data?.schoolClassTestId);
			setStatus(response.data.status);

			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const toggleHowToPlay = (section) => {
		if (!testAvailable && isStudent) {
			dispatch(showToast("Test is not available. Try after some time or contact to your respective class teacher | school."));
			return;
		}

		if (status !== 0) {
			let setStateFunction;

			if (section.url === URL_PURSUIT) setStateFunction = setPursuitHowToPlay;
			if (section.url === URL_RAPID_FIRE) setStateFunction = setRapidFireHowToPlay;

			if (setStateFunction)
				setStateFunction((prev) => {
					const copyPrev = { ...prev };

					if (!copyPrev.open) {
						copyPrev.url = section.url;
						copyPrev.subTitle = section.subTitle;
						copyPrev.id = section.id;
					}

					copyPrev.open = !copyPrev.open;

					return copyPrev;
				});
		} else dispatch(showToast("No Active Tests found."));
	};

	const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

	const gameListUi = () => {
		return (
			<GridContainer className="flex f-v-center f-h-center overlay-effect">
				{sections.map((section) => (
					<Fragment key={section.name}>
						{section.isLive ? (
							<div className="box">
								<div className={`box-content `} onClick={() => !loading && section.isTestAvailable && toggleHowToPlay(section)}>
									<div className={`img-container ${!section.isTestAvailable && "disabled"}`} style={{ backgroundImage: `url(${section.image})` }} onMouseOver={play}></div>

									<div className="progress-container full-width" onMouseOver={(e) => e.preventDefault()}>
										<Progress value={section.value} />
									</div>
								</div>
							</div>
						) : (
							<CustomTooltip title="Coming soon!!!" placement="top">
								<div className="box">
									<div className={`box-content `}>
										<div className="img-container" style={{ backgroundImage: `url(${section.image})` }} onMouseOver={play}></div>
									</div>
								</div>
							</CustomTooltip>
						)}
					</Fragment>
				))}
			</GridContainer>
		);
	};

	return (
		<PageWrapper>
			<LazyImage compressedImage={IMAGES.ECONOMICS.COMPRESSED_COMMON_BACKGROUND} image={IMAGES.ECONOMICS.COMMON_BACKGROUND} wrapperProps={{ className: "background-image" }} />

			{loading && (
				<div className="overlay-stackrank">
					<CircularProgress className="progress" />
				</div>
			)}

			<div className="title-history"></div>

			<div className="content">{gameListUi()}</div>

			<Scoreboard peoples={scoreboard} />

			{pursuitHowToPlay.open && (
				<PursuitHowToPlay
					open={pursuitHowToPlay.open}
					handleClose={toggleHowToPlay}
					data={pursuitHowToPlay}
					subjectId={subjectId}
					pursuitDetails={pursuitDetails}
					sidebar={false}
					schoolClassTestId={schoolClassTestId}
				/>
			)}

			{rapidFireHowToPlay.open && (
				<RapidFireHowToPlay open={rapidFireHowToPlay.open} handleClose={toggleHowToPlay} data={rapidFireHowToPlay} subjectId={subjectId} sidebar={false} schoolId={schoolClassTestId} />
			)}
		</PageWrapper>
	);
};

const Progress = (props) => (
	<ProgressWrapper {...props}>
		<div className="outer">
			<div className="active" />
		</div>
		<div className="text">{props.value}%</div>
	</ProgressWrapper>
);

const Scoreboard = (props) => (
	<ScoreboardWrapper>
		<div className="people-cards">
			{props?.peoples?.map((person) => (
				<div className="person flex f-v-center f-h-center" key={person.winnerPlace}>
					<div className="content text-center">
						<div className="image" style={{ backgroundImage: `url(${person.profileImage})` }}></div>
						<div className="name elipsis">{person.firstName}</div>
						<div className="score">{person.totalScore}</div>
					</div>
				</div>
			))}
		</div>
	</ScoreboardWrapper>
);

export default Dashboard;
