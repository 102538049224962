import React, { Fragment, useEffect, useState } from "react";
import useSound from "use-sound";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Tooltip } from "@material-ui/core";
// REDUX
import { quizSound, showToast } from "Redux/General/Actions";
import { updateTotalScore, schoolClassTestID } from "Redux/Auth/Actions";
// MODALS
import HowToPlay from "Components/Science/HowToPlay/HowToPlay";
import { HowToPlay as HowToPlayWeakestLink } from "Components/Science/WeakestLink/HowToPlay/HowToPlay";
// STYLES
import { PageWrapper, GridContainer, ScoreboardWrapper, ProgressWrapper } from "./Dashboard.style";
// HELPERS
import { random, Api, URL_RAPID_FIRE, SCIENCE_MODULE_ID, URL_PERIODIC_TABLE, URL_WEAKEST_LINK_GAME, IMAGES } from "Helpers";
// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";
import LazyImage from "Components/Commons/LazyImage/LazyImage";

const tooltipStyles = { tooltip: { padding: "8px 18px", borderRadius: "10px", marginBottom: "22px", fontSize: "14px", backgroundColor: "white", color: "black" } };

const sectionConst = [
	{ value: 0, isLive: true, name: "Rapid-fire", url: URL_RAPID_FIRE, image: IMAGES.SCIENCE.RAPID_FIRE_CARD, subTitle: "RAPID FIRE", id: SCIENCE_MODULE_ID.RAPID_FIRE, isTestAvailable: false },
	{
		value: 0,
		isLive: true,
		name: "Periodic-table",
		url: URL_PERIODIC_TABLE,
		image: IMAGES.SCIENCE.PERIODIC_TABLE_CARD,
		subTitle: "PERIODIC TABLE",
		id: SCIENCE_MODULE_ID.PERIODIC_TABLE,
		isTestAvailable: false,
	},
	{
		value: 0,
		isLive: true,
		name: "Weakest-link",
		url: URL_WEAKEST_LINK_GAME,
		image: IMAGES.SCIENCE.LOOSE_LINK_CARD,
		subTitle: "WEAKEST LINK",
		id: SCIENCE_MODULE_ID.WEAKEST_LINK,
		isTestAvailable: false,
	},
];

const Dashboard = () => {
	const dispatch = useDispatch();

	const subjects = useSelector((state) => state.Auth.subjectAccessList);
	const { gamesStatus } = useSelector((state) => state.GamesData);

	const subjectId = subjects?.filter((item) => item.subject === "Science")[0].subjectId;

	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const [sections, setSections] = useState([...sectionConst]);
	const [scoreboard, setScoreboard] = useState([]);
	const [isStudent, setStudent] = useState(false);
	const [testAvailable, setTestStatus] = useState(false);
	const [status, setStatus] = useState(0);
	const [loading, setLoading] = useState(false);
	const [schoolId, setSchoolId] = useState("");
	const [howToPlay, setHowToPlay] = useState({ open: false, subTitle: "", url: null });
	const [weakestLinkHowToPlay, setWeakestLinkHowToPlay] = useState({ open: false, subTitle: "", url: null });

	useEffect(() => {
		dispatch(quizSound(true));

		setTimeout(() => {
			const newSections = [...sections];

			newSections.forEach((section) => {
				if (!section.isLive) section.value = random(30, 100);
			});

			setSections(newSections);
		}, 1500);
	}, []); // eslint-disable-line

	useEffect(() => {
		if (Object.keys(gamesStatus).length) getStatus();
	}, [gamesStatus]); // eslint-disable-line

	const getStatus = async () => {
		try {
			setLoading(true);

			let response = await new Api().get("general/studentStatus", { params: { subjectId: subjectId } });

			const percentValue = (sub, total) => ((sub * 100) / total).toFixed(0);

			// SET PROGRESS
			const newValues = [...sections];

			newValues.forEach((section) => {
				switch (section.id) {
					case SCIENCE_MODULE_ID.RAPID_FIRE:
						if (response?.data?.rapidFireScience?.isRapidFireScienceComplete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.rapidFireScience?.totalRightAnswerRapidFireScience, response?.data?.rapidFireScience?.totalQuestionRapidFireScience);
							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isRapidFireScienceActive) section.isTestAvailable = true;

						break;

					case SCIENCE_MODULE_ID.PERIODIC_TABLE:
						if (response?.data?.periodicTable?.isPeriodicTableComplete && response.data.periodicTable.isPeriodicTableComplete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.periodicTable?.totalRightElementsPeriodicTable, response?.data?.periodicTable?.totalElementPeriodicTable);
							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isPeriodicTableActive) section.isTestAvailable = true;

						break;
					case SCIENCE_MODULE_ID.WEAKEST_LINK:
						if (response?.data?.looseLink?.isLooseLinkComplete && response.data.looseLink.isLooseLinkComplete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.looseLink?.totalRightAnswerLooseLink, response?.data?.looseLink?.totalQuestionLooseLink);
							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isLooseLinkActive) section.isTestAvailable = true;

						break;

					default:
						section.value = 0;

						break;
				}
			});

			dispatch(updateTotalScore(response.data.total));
			dispatch(schoolClassTestID(response?.data?.schoolClassTestId));

			setSections(newValues);
			setScoreboard(response.data.scoreDashBoardList);
			setTestStatus(response?.data?.isLiveTestAvailable);
			setStudent(response?.data?.isStudentLogin);
			setStatus(response.data.status);
			setSchoolId(response?.data?.schoolClassTestId);

			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const toggleHowToPlay = (section) => {
		if (!testAvailable && isStudent) {
			dispatch(showToast("Test is not available. Try after some time or contact to your respective class teacher | school."));
			return;
		}

		if (status !== 0) {
			let setStateFunction;

			if (section.url === URL_RAPID_FIRE || section.url === URL_PERIODIC_TABLE) setStateFunction = setHowToPlay;
			if (section.url === URL_WEAKEST_LINK_GAME) setStateFunction = setWeakestLinkHowToPlay;

			if (setStateFunction)
				setStateFunction((prev) => {
					const copyPrev = { ...prev };

					if (!copyPrev.open) {
						copyPrev.url = section.url;
						copyPrev.subTitle = section.subTitle;
						copyPrev.id = section.id;
					}

					copyPrev.open = !copyPrev.open;

					return copyPrev;
				});
		} else dispatch(showToast("No Active Tests found."));
	};

	const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

	const gameListUi = () => {
		return (
			<GridContainer className="flex f-v-center f-h-center overlay-effect">
				{sections.map((section) => (
					<Fragment key={section.name}>
						{section.isLive ? (
							<div className="box">
								<div className={`box-content `} onClick={() => !loading && section.isTestAvailable && toggleHowToPlay(section)}>
									<div className={`img-container ${!section.isTestAvailable && "disabled"}`} style={{ backgroundImage: `url(${section.image})` }} onMouseOver={play}></div>
									<div className="progress-container full-width" onMouseOver={(e) => e.preventDefault()}>
										<Progress value={section.value} />
									</div>
								</div>
							</div>
						) : (
							<CustomTooltip title="Coming soon!!!" placement="top">
								<div className="box" key={section.name}>
									<div className={`box-content `}>
										<div className="img-container" style={{ backgroundImage: `url(${section.image})` }} onMouseOver={play}></div>
										<div className="progress-container full-width" onMouseOver={(e) => e.preventDefault()}>
											<Progress value={section.value} />
										</div>
									</div>
								</div>
							</CustomTooltip>
						)}
					</Fragment>
				))}
			</GridContainer>
		);
	};

	return (
		<PageWrapper>
			<LazyImage compressedImage={IMAGES.SCIENCE.COMPRESSED_COMMON_BACKGROUND} image={IMAGES.SCIENCE.COMMON_BACKGROUND} wrapperProps={{ className: "background-image" }} />
			<div className="title-history"></div>

			<div className="content">
				{loading ? (
					<div className="overlay-stackrank">
						{gameListUi()}
						<div className="display-circular-progress">
							<CircularProgress />
						</div>
					</div>
				) : (
					<>{gameListUi()}</>
				)}
			</div>

			<Scoreboard peoples={scoreboard} />

			{howToPlay.open && <HowToPlay open={howToPlay.open} handleClose={toggleHowToPlay} data={howToPlay} subjectId={subjectId} schoolId={schoolId} />}

			{weakestLinkHowToPlay.open && <HowToPlayWeakestLink open={weakestLinkHowToPlay.open} handleClose={toggleHowToPlay} data={weakestLinkHowToPlay} subjectId={subjectId} schoolId={schoolId} />}
		</PageWrapper>
	);
};

const Progress = (props) => (
	<ProgressWrapper {...props}>
		<div className="outer">
			<div className="active" />
		</div>
		<div className="text">{props.value}%</div>
	</ProgressWrapper>
);

const Scoreboard = (props) => (
	<ScoreboardWrapper>
		<div className="people-cards">
			{props?.peoples?.map((person) => (
				<div className="person flex f-v-center f-h-center" key={person.winnerPlace}>
					<div className="content text-center">
						<div className="image" style={{ backgroundImage: `url(${person.profileImage})` }}></div>
						<div className="name elipsis">{person.firstName}</div>
						<div className="score">{person.totalScore}</div>
					</div>
				</div>
			))}
		</div>
	</ScoreboardWrapper>
);

export default Dashboard;
