import styled, { keyframes } from "styled-components";
import { Dialog } from "@material-ui/core";
// HELPERS
import { QUESTION_TYPES, IMAGES } from "Helpers";
import { COLORS, responsive } from "Styles/Constants";

const TurnAnimation = keyframes`
	0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
`;

export const GamePageWrapper = styled.div`
	width: 100%;
	color: #fff;
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	position: relative;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.background-image {
		width: 100%;
		z-index: -1;
		height: 100%;
		position: absolute;
	}

	.play-logo-img {
		width: 450px;
	}

	.btn-container {
		width: 400px;
		padding: 4px;
		border-radius: 10px;
		margin: 70px auto 0px;
		background: transparent;
		transition: opacity 0.3s ease-in-out;
		animation: rounded 20s linear infinite;

		.done-btn {
			z-index: 1;
			width: 100%;
			color: #fff;
			font-size: 28px;
			filter: blur(2px);
			padding: 5px 40px;
			border-radius: 10px;
			background-size: 400%;
			letter-spacing: 1.6px;
			text-shadow: 0.3px 2px 0 #041727;
			box-shadow: -0.4px 5px 0 0 #085264;
			transition: opacity 0.3s ease-in-out;
			background: linear-gradient(to top, #83ebdf, #3491b4, #80ebe1);

			&.enable {
				cursor: pointer;
				filter: blur(0px);
			}
		}
	}
`;

export const GamePlayWrapper = styled.div`
	width: 100%;
	color: #fff;
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	position: relative;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.background-image {
		width: 100%;
		z-index: -1;
		height: 100%;
		position: absolute;
	}

	.progress {
		color: #fff !important;
	}

	.menu-container {
		width: 720px;
		height: 570px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.MATH.MATHAHTZEE.MENU_BOARD});

		${responsive.LAPTOP`
			width: 630px;
    		height: 500px;
		`}

		.title {
			top: 0;
			margin: 0px;
			font-size: 38px;
			margin-top: 22px;
			position: absolute;
			left: calc(50% - 54px);
		}

		.options-container {
			height: 400px;
			padding: 20px;
			margin: 80px 20px 0px 20px;

			${responsive.LAPTOP`
				height: 370px;
			`}

			.play-with-container {
				display: flex;
				color: #040404;
				margin-bottom: 15px;
				align-items: center;
				height: calc(100% - 150px);
				justify-content: space-between;

				.play-with-friends-container {
					width: 99%;
					height: 100%;
					display: flex;
					cursor: pointer;
					margin-right: 2%;
					border-radius: 10px;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					background-color: #cc8c56;
					box-shadow: 0px 2px 3px 0 #fff, inset -0.3px 3px 7px 0 rgba(0, 0, 0, 0.35);

					.image {
						width: 100px;
						height: 100px;
						border-radius: 50%;
						margin-bottom: 15px;
						transition: all 0.5s;
						background-size: 100% 100%;
						background-image: url(${IMAGES.MATH.MATHAHTZEE.PLAY_WITH_FRIEND});

						&.active {
							box-shadow: 0px 0px 10px 6px rgb(255, 216, 71);
						}
					}
				}

				.play-with-computer-container {
					width: 99%;
					height: 100%;
					display: flex;
					cursor: pointer;
					border-radius: 10px;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					background-color: #cc8c56;
					box-shadow: 0px 2px 3px 0 #fff, inset -0.3px 3px 7px 0 rgba(0, 0, 0, 0.35);

					.image {
						width: 100px;
						height: 100px;
						box-shadow: none;
						border-radius: 50%;
						margin-bottom: 15px;
						transition: all 0.5s;
						background-size: 100% 100%;
						background-image: url(${IMAGES.MATH.MATHAHTZEE.PLAY_WITH_COMPUTER});

						&.active {
							box-shadow: 0px 0px 10px 6px rgb(255, 216, 71);
						}
					}
				}
			}

			.sound-container {
				height: 60px;
				display: flex;
				color: #040404;
				padding: 0px 20px;
				border-radius: 10px;
				align-items: center;
				background-color: #cc8c56;
				justify-content: space-between;
				box-shadow: 0px 2px 3px 0 #fff, inset -0.3px 3px 7px 0 rgba(0, 0, 0, 0.35);

				.switch {
					width: 60px;
					height: 30px;
					padding: 4px;
					cursor: pointer;
					transition: all 0.5s;
					background-size: 45%;
					border-radius: 28.9px;
					background-color: #1d2614;
					background-position: 2% 78%;
					background-repeat: no-repeat;
					background-image: url(${IMAGES.MATH.MATHAHTZEE.NO_VOLUME});

					&.active {
						background-position: 98% 78%;
						background-image: url(${IMAGES.MATH.VOLUME});
					}
				}
			}

			.btn-container {
				width: 400px;
				padding: 5px;
				padding-bottom: 9px;
				border-radius: 10px;
				margin: 50px auto 0px;
				transition: opacity 0.3s ease-in-out;
				background-color: rgb(73 73 73 / 14%);
				animation: rounded 20s linear infinite;

				.done-btn {
					z-index: 1;
					width: 100%;
					color: #fff;
					font-size: 22px;
					font-weight: bold;
					filter: blur(2px);
					padding: 5px 40px;
					border-radius: 10px;
					background-size: 400%;
					letter-spacing: 1.6px;
					text-shadow: 0.3px 2px 0 #041727;
					box-shadow: -0.4px 4px 0 0 #085264;
					transition: opacity 0.3s ease-in-out;
					background: linear-gradient(to top, #83ebdf, #3491b4, #80ebe1);

					&.enable {
						cursor: pointer;
						filter: blur(0px);
					}
				}
			}
		}
	}

	.pairing-container {
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;

		&.column {
			flex-direction: column;
		}

		.loader {
			color: #19270e;
		}

		.pairing-text {
			font-size: 20px;
		}
	}

	.main-game-play-container {
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.left-container {
		width: 50%;
		height: 80%;
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;

		.large-logo-container {
			width: 100%;
			opacity: 0.5;
			height: 100px;
			position: absolute;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(${IMAGES.MATH.MATHAHTZEE.MATHAHTZEE_LARGE});
		}

		.roll-container {
			height: 100%;
			display: flex;
			position: relative;
			align-items: center;
			flex-direction: column;
			justify-content: flex-end;

			.dices-container {
				width: 80%;
				display: flex;
				max-width: 590px;
				align-items: center;
				margin-bottom: 30px;
				justify-content: space-between;

				${responsive.DISPLAY`
					max-width: 470px;
				`}

				${responsive.DESKTOP`
				    width: 70%;
					max-width: 370px;
				`}

				.dice-container {
					width: 80px;
					height: 80px;
					margin-right: 20px;
					border-radius: 5px;
					background-color: rgba(0, 0, 0, 0.15);

					${responsive.DISPLAY`
						width: 60px;
    					height: 60px;
					`}

					${responsive.DESKTOP`
						width: 45px;
						height: 45px;
					`}

					&:last-child {
						margin-right: 0px;
					}

					.dice {
						width: 80px;
						height: 80px;
						position: absolute;
						background-size: 80% 80%;
						transition: all 0.5s ease;
						background-position: center;
						background-repeat: no-repeat;

						${responsive.DISPLAY`
							width: 60px;
							height: 60px;
						`}

						${responsive.DESKTOP`
							width: 45px;
    						height: 45px;
						`}

						&.selected {
							cursor: pointer;
						}

						&.one {
							cursor: pointer;
							background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_ONE});
						}

						&.two {
							cursor: pointer;
							background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_TWO});
						}

						&.three {
							cursor: pointer;
							background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_THREE});
						}

						&.four {
							cursor: pointer;
							background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_FOUR});
						}

						&.five {
							cursor: pointer;
							background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_FIVE});
						}

						&.six {
							cursor: pointer;
							background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_SIX});
						}
					}
				}
			}

			.btn-container {
				width: 600px;
				padding: 5px;
				padding-bottom: 9px;
				border-radius: 10px;
				margin: 0px auto 0px;
				background-color: rgba(0, 0, 0, 0.15);
				transition: opacity 0.3s ease-in-out;
				animation: rounded 20s linear infinite;

				${responsive.DISPLAY`
					width: 450px;
				`}

				${responsive.DESKTOP`
					width: 370px;
				`}

				.done-btn {
					z-index: 1;
					width: 100%;
					color: #fff;
					height: 60px;
					cursor: unset;
					display: flex;
					font-size: 30px;
					font-weight: bold;
					filter: blur(2px);
					padding: 5px 40px;
					align-items: center;
					border-radius: 10px;
					background-size: 400%;
					text-shadow: 0.3px 2px 0 #041727;
					box-shadow: -0.4px 4px 0 0 #085264;
					transition: opacity 0.3s ease-in-out;
					background: linear-gradient(to top, #83ebdf, #3491b4, #80ebe1);

					${responsive.DISPLAY`
						height: 40px;
						font-size: 22px;
					`}

					${responsive.DESKTOP`
						height: 35px;
						font-size: 22px;
					`}

					&.enable {
						cursor: pointer;
						filter: blur(0px);
					}

					.text {
						height: 48px;

						${responsive.DISPLAY`
							height: 34px;
						`}

						${responsive.DESKTOP`
							height: 34px;
						`}
					}

					.remaining-turn {
						width: 30px;
						height: 30px;
						display: flex;
						color: #3692b5;
						font-size: 21px;
						margin-left: 10px;
						text-shadow: none;
						border-radius: 5px;
						align-items: center;
						justify-content: center;
						background-color: #c4f1fc;
						box-shadow: 2px 3px 0 0 #1e7a91;

						${responsive.DISPLAY`
							width: 22px;
    						height: 22px;
							font-size: 16px;
						`}

						${responsive.DESKTOP`
							width: 22px;
    						height: 22px;
							font-size: 16px;
						`}
					}
				}
			}
		}

		.player-container {
			display: flex;
			align-items: center;
			flex-direction: column;

			.player-img {
				width: 150px;
				height: 150px;
				border-radius: 50%;
				border: 3px solid #fff;
			}

			.btn-container {
				width: 400px;
				padding: 4px;
				border-radius: 10px;
				margin: 20px auto;
				background: transparent;
				transition: opacity 0.3s ease-in-out;
				animation: rounded 20s linear infinite;

				.roll-btn {
					z-index: 1;
					width: 100%;
					min-height: 40px;
					color: #fff;
					font-size: 28px;
					font-weight: bold;
					filter: blur(2px);
					padding: 5px 10px;
					border-radius: 10px;
					background-size: 400%;
					text-shadow: 0.3px 2px 0 #041727;
					box-shadow: -0.4px 5px 0 0 #085264;
					transition: opacity 0.3s ease-in-out;
					background: linear-gradient(to top, #83ebdf, #3491b4, #80ebe1);

					&.enable {
						cursor: pointer;
						filter: blur(0px);
					}
				}
			}

			.dices-container {
				width: 70%;
				display: flex;
				margin: 30px 0px;
				align-items: center;
				justify-content: space-between;

				.dice {
					width: 57px;
					height: 57px;
					margin-right: 20px;
					border-radius: 5px;
					background-size: 80% 80%;
					background-position: center;
					background-repeat: no-repeat;
					background-color: rgba(0, 0, 0, 0.15);

					&.blue-one {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.BLUE_DICE_ONE});
					}

					&.blue-two {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.BLUE_DICE_TWO});
					}

					&.blue-three {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.BLUE_DICE_THREE});
					}

					&.blue-four {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_FOUR});
					}

					&.blue-five {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.BLUE_DICE_FIVE});
					}

					&.blue-six {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.BLUE_DICE_SIX});
					}

					&.red-one {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_ONE});
					}

					&.red-two {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_TWO});
					}

					&.red-three {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_THREE});
					}

					&.red-four {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_FOUR});
					}

					&.red-five {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_FIVE});
					}

					&.red-six {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_SIX});
					}
				}
			}
		}
	}

	.right-container {
		width: 50%;
		height: 100%;
		display: flex;
		padding-bottom: 0%;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		.opponent-container {
			padding: 10px;
			font-size: 20px;
		}

		.board {
			width: 680px;
			height: 810px;
			display: flex;
			position: relative;
			align-items: center;
			flex-direction: column;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(${IMAGES.MATH.MATHAHTZEE.GAME_PLAY_BOARD});

			${responsive.DISPLAY`
				width: 520px;
    			height: 620px;
			`}

			${responsive.DESKTOP`
				width: 360px;
    			height: 430px;
			`}

			.board-header {
				gap: 10px;
				width: 655px;
				height: 104px;
				display: flex;
				margin: 12px 0px;
				align-items: center;
				justify-content: center;

				${responsive.DISPLAY`
					width: 496px;
    				height: 75px;
				`}

				${responsive.DESKTOP`
					width: 340px;
    				height: 48px;
				`}

				.score-box {
					z-index: 0;
					width: 100%;
					height: 100%;
					color: white;
					display: flex;
					padding: 10px;
					position: relative;
					align-items: center;
					border-radius: 10px;
					box-shadow: -0.3px 4px 0 0 rgba(0, 0, 0, 0.13);

					&::before {
						top: -1px;
						left: -1px;
						content: "";
						z-index: -1;
						filter: blur(2px);
						position: absolute;
						border-radius: 10px;
						width: calc(100% + 2px);
						height: calc(100% + 2px);
						transition: all 0.3s ease-in-out;
						box-shadow: 0px 0px 0px 4px #ffffff;
						animation: ${TurnAnimation} 30s linear infinite;
						background: linear-gradient(45deg, #00d7ff 3%, #fff 3% 50%, #00daff 50% 53%, #fff 53% 100%);
						background-size: 400%;
					}

					&::after {
						top: 0px;
						left: 0px;
						z-index: -1;
						width: 100%;
						content: "";
						height: 100%;
						position: absolute;
						border-radius: 10px;
						background-size: 400%;
						transition: opacity 0.3s ease-in-out;
					}

					&.red {
						&::after {
							background-image: linear-gradient(to top, #920f0b, #f43b35);
						}
					}

					&.blue {
						&::after {
							background-image: linear-gradient(to top, #0067e6, #003678);
						}
					}

					&.filter {
						&::before {
							content: none;
						}

						.user-image {
							border: solid 5px #fff;
						}

						.user-card {
							border: solid 5px #fff;
						}
					}

					.user-image {
						width: 110px;
						height: 80px;
						border-radius: 50%;
						border: solid 5px #fff;

						${responsive.DISPLAY`
							width: 80px;
    						height: 60px;
						`}

						${responsive.DESKTOP`
							width: 55px;
    						height: 38px;
							border: solid 3px #fff;
						`}
					}

					.user-card {
						width: 110px;
						height: 80px;
						display: flex;
						font-size: 28px;
						font-weight: bold;
						border-radius: 50%;
						align-items: center;
						justify-content: center;
						border: solid 5px #fff;

						${responsive.DISPLAY`
							width: 80px;
    						height: 60px;
						`}

						${responsive.DESKTOP`
							width: 55px;
    						height: 37px;
							font-size: 20px;
							border: solid 3px #fff;
						`}

						&.red {
							background-color: #f43b35;
						}

						&.blue {
							background-image: #0072ff;
						}
					}

					.user-name-and-score {
						width: 100%;
						display: flex;
						text-align: right;
						font-weight: bold;
						flex-direction: column;

						.user-name {
							width: 140px;
							font-size: 28px;
							overflow: hidden;
							margin-left: auto;
							white-space: nowrap;
							text-overflow: ellipsis;
							text-shadow: 1px 3px 0 #041727;

							${responsive.DISPLAY`
								width: 135px;
								font-size: 20px;
							`}

							${responsive.DESKTOP`
								width: 80px;
    							font-size: 12px;
							`}
						}

						.score {
							font-size: 35px;
							text-shadow: 1px 3px 0 #041727;

							${responsive.DISPLAY`
								font-size: 20px;
							`}

							${responsive.DESKTOP`
								font-size: 18px;
							`}
						}
					}
				}
			}

			.board-scores {
				width: 100%;
				display: flex;
				align-items: center;

				.dice-score {
					width: 50%;
					display: flex;
					flex-direction: column;

					.dice-and-score-container {
						display: flex;
						padding: 11px;
						align-items: center;

						${responsive.DISPLAY`
							padding: 13.5px;
						`}

						${responsive.DESKTOP`
							padding: 9px;
						`}

						.tooltip {
							display: flex;
							flex-direction: column;

							.heading {
								font-size: 20px;
								font-weight: bold;
							}

							.title {
							}

							.description {
							}
						}

						.dice {
							width: 74px;
							height: 74px;
							margin-right: 20px;
							border-radius: 5px;
							transition: all 0.2s;
							background-size: 100% 100%;
							background-position: center;
							background-repeat: no-repeat;

							${responsive.DISPLAY`
								width: 46px;
    							height: 46px;
							`}

							${responsive.DESKTOP`
								width: 32px;
    							height: 32px;
							`}

							&.one {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_ONE});
							}

							&.two {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_TWO});
							}

							&.three {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_THREE});
							}

							&.four {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_FOUR});
							}

							&.five {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_FIVE});
							}

							&.six {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_DICE_SIX});
							}

							&.three-x {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.DICE3X});
							}

							&.four-x {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.DICE4X});
							}

							&.home {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.DICE_HOME});
							}

							&.small {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.DICE_SMALL});
							}

							&.large {
								background-image: url(${IMAGES.MATH.MATHAHTZEE.DICE_LARGE});
							}

							&.chance {
								display: flex;
								font-size: 10px;
								text-align: center;
								align-items: center;
								flex-direction: column;
								justify-content: center;
								background-image: url(${IMAGES.MATH.MATHAHTZEE.DICE_CHANGE});

								${responsive.DISPLAY`
									font-size: 8px;
								`}

								${responsive.DESKTOP`
									font-size: 6px;
								`}

								.large {
									font-size: 20px;
									font-weight: bold;
								}
							}
						}

						.score {
							width: 74px;
							height: 74px;
							display: flex;
							color: #6f4a0f;
							font-size: 40px;
							font-weight: bold;
							border-radius: 5px;
							margin-right: 20px;
							align-items: center;
							justify-content: center;
							background-color: #ca8800;
							box-shadow: -0.1px 1px 2px 0 rgba(0, 0, 0, 0.68);

							${responsive.DISPLAY`
							    width: 46px;
    							height: 46px;
								font-size: 26px;
							`}

							${responsive.DESKTOP`
								width: 32px;
    							height: 32px;
								font-size: 20px;
							`}

							&.light {
								color: #ffc468;
							}

							&.pointer {
								cursor: pointer;
							}

							&.selected {
								box-shadow: 0px 0px 10px 2px #6f4a0f;
							}
						}

						.op-score {
							width: 74px;
							height: 74px;
							color: white;
							display: flex;
							font-size: 40px;
							font-weight: bold;
							border-radius: 5px;
							align-items: center;
							justify-content: center;
							background-color: #0a6ece;
							box-shadow: -0.1px 1px 2px 0 rgba(0, 0, 0, 0.68);

							${responsive.DISPLAY`
								width: 46px;
    							height: 46px;
								font-size: 26px;
							`}

							${responsive.DESKTOP`
								width: 32px;
    							height: 32px;
								font-size: 20px;
							`}
						}
					}
				}
			}

			.board-footer {
				width: 100%;
				display: flex;
				padding: 10px 11px;
				align-items: center;

				.bonus-container {
					width: 34%;
					display: flex;
					color: #0f0f0f;
					user-select: none;
					align-items: center;

					.bonus-text-container {
						display: flex;
						margin-right: 15px;
						flex-direction: column;

						${responsive.DISPLAY`
							margin-right: 12px;
						`}

						${responsive.DESKTOP`
							width: 32px;
							height: 32px;
							font-size: 20px;
						`}

						.bonus-text {
							font-size: 22px;
							text-align: center;
							margin-bottom: -5px;

							${responsive.DISPLAY`
								font-size: 16px;
							`}

							${responsive.DESKTOP`
								font-size: 12px;
							`}
						}

						.bonus-number {
							font-size: 32px;
							font-weight: bold;
							text-align: center;

							${responsive.DISPLAY`
								font-size: 24px;
							`}

							${responsive.DESKTOP`
								font-size: 18px;
							`}
						}
					}

					.bonus-icon-container {
						width: 80px;
						height: 80px;
						display: flex;
						border-radius: 50%;
						align-items: center;
						justify-content: center;

						${responsive.DISPLAY`
							width: 60px;
    						height: 60px;
						`}

						${responsive.DESKTOP`
							width: 40px;
    						height: 40px;
						`}

						.bonus-round-box {
							width: 88%;
							height: 88%;
							display: flex;
							font-size: 22px;
							border-radius: 50%;
							align-items: center;
							justify-content: center;
							background-color: #efbe7b;

							${responsive.DISPLAY`
								font-size: 16px;
							`}

							${responsive.DESKTOP`
								font-size: 10px;
							`}

							.slash {
								margin: 0px 2px;
							}
						}
					}
				}

				.mathathzee-container {
					display: flex;
					align-items: center;

					.logo {
						width: 188px;
						height: 50px;
						margin-right: 23px;
						background-size: 100% 100%;
						background-position: center;
						background-repeat: no-repeat;
						background-image: url(${IMAGES.MATH.MATHAHTZEE.MATHAHTZEE_SMALL});

						${responsive.DISPLAY`
							width: 137px;
    						height: 38px;
						`}

						${responsive.DESKTOP`
							width: 101px;
    						height: 28px;
							margin-right: 14px;
						`}
					}

					.final-score {
						width: 70px;
						height: 70px;
						display: flex;
						color: #6f4a0f;
						font-size: 40px;
						font-weight: bold;
						border-radius: 5px;
						margin-right: 20px;
						align-items: center;
						justify-content: center;
						background-color: #ca8800;
						box-shadow: -0.1px 1px 2px 0 rgba(0, 0, 0, 0.68);

						&.light {
							color: #ffc468;
						}

						&.pointer {
							cursor: pointer;
						}

						${responsive.DISPLAY`
							width: 46px;
							height: 46px;
							font-size: 26px;
						`}

						${responsive.DESKTOP`
							width: 32px;
							height: 32px;
							font-size: 20px;
						`}
					}

					.op-score {
						width: 74px;
						height: 74px;
						color: white;
						display: flex;
						font-size: 40px;
						font-weight: bold;
						border-radius: 5px;
						align-items: center;
						justify-content: center;
						background-color: #0a6ece;
						box-shadow: -0.1px 1px 2px 0 rgba(0, 0, 0, 0.68);

						${responsive.DISPLAY`
							width: 46px;
							height: 46px;
							font-size: 26px;
						`}

						${responsive.DESKTOP`
							width: 32px;
    						height: 32px;
							font-size: 20px;
						`}
					}
				}
			}
		}
	}
`;

export const FinalModalWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		color: black;
		overflow: visible;
		position: inherit;
		background: transparent;
	}

	.MuiDialog-paperWidthSm {
		max-width: 900px;
	}

	.confetti {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.box-modal {
		width: 630px;
		display: flex;
		height: 580px;
		padding-top: 30px;
		position: relative;
		align-items: center;
		flex-direction: column;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: url(${(props) => (props.win ? IMAGES.MATH.MATHAHTZEE.CONGRATULATIONS_BACKGROUND : IMAGES.MATH.MATHAHTZEE.QUESTION_MODAL)});

		.you-win {
			width: 100%;
			height: 70px;
			color: white;
			font-size: 38px;
			margin-top: 55px;
			padding-top: 20px;
			text-align: center;
			font-weight: bolder;

			${(props) =>
				props.win
					? `
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(${IMAGES.MATH.MATHAHTZEE.YOU_WIN});
			`
					: ``}
		}

		.you-lose {
			color: #fff;
			font-size: 35px;
			font-weight: bold;
		}

		.players-container {
			width: 100%;
			display: flex;
			margin-top: 60px;
			align-items: center;

			.player {
				width: 50%;
				display: flex;
				user-select: none;
				position: relative;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				.crown {
					top: 0;
					z-index: 2;
					width: 75px;
					height: 75px;
					margin-top: -45px;
					position: absolute;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url(${IMAGES.MATH.MATHAHTZEE.CROWN});
				}

				.player-card {
					color: white;
					width: 100px;
					height: 100px;
					display: flex;
					font-size: 35px;
					padding-top: 4px;
					font-weight: bold;
					border-radius: 50%;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;

					&.red {
						box-shadow: 0px 0px 15px 8px #fc2424;
						background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_USER_BACKGROUND});
					}

					&.blue {
						box-shadow: 0px 0px 15px 8px #569ff0;
						background-image: url(${IMAGES.MATH.MATHAHTZEE.BLUE_USER_BACKGROUND});
					}
				}

				.player-name {
					width: 80%;
					color: white;
					font-size: 20px;
					overflow: hidden;
					margin-top: 20px;
					font-weight: bold;
					text-align: center;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				.player-score {
					color: white;
					width: 125px;
					height: 60px;
					display: flex;
					font-size: 30px;
					margin-top: 15px;
					font-weight: bold;
					align-items: center;
					justify-content: center;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;

					&.red {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.RED_SCORE_BACKGROUND});
					}

					&.blue {
						background-image: url(${IMAGES.MATH.MATHAHTZEE.BLUE_SCORE_BACKGROUND});
					}
				}
			}
		}

		.btn-container {
			display: flex;
			margin-top: 40px;
			align-items: center;
			justify-content: center;

			.replay-btn-container {
				width: 116px;
				padding: 4px;
				margin-right: 20px;
				border-radius: 10px;
				background: transparent;
				transition: opacity 0.3s ease-in-out;
				animation: rounded 20s linear infinite;

				.replay-btn {
					z-index: 1;
					width: 100%;
					color: #fff;
					height: 40px;
					font-size: 18px;
					font-weight: bold;
					filter: blur(2px);
					padding: 5px 40px;
					border-radius: 10px;
					background-size: 400%;
					letter-spacing: 1.6px;
					text-shadow: 0.3px 2px 0 #041727;
					box-shadow: -0.4px 5px 0 0 #085264;
					transition: opacity 0.3s ease-in-out;
					background: linear-gradient(to top, #83ebdf, #3491b4, #80ebe1);

					&.enable {
						cursor: pointer;
						filter: blur(0px);
					}
				}
			}

			.home-btn-container {
				width: 110px;
				padding: 4px;
				height: 52px;
				border-radius: 10px;
				background: transparent;
				transition: opacity 0.3s ease-in-out;
				animation: rounded 20s linear infinite;

				.home-btn {
					z-index: 1;
					color: #fff;
					width: 110px;
					height: 48px;
					font-size: 20px;
					font-weight: bold;
					filter: blur(2px);
					padding: 5px 40px;
					border-radius: 10px;
					letter-spacing: 1.6px;
					background-size: contain;
					background-repeat: no-repeat;
					background-image: url(${IMAGES.MATH.MATHAHTZEE.HOME});

					&.enable {
						cursor: pointer;
						filter: blur(0px);
					}
				}
			}
		}
	}
`;

export const QuestionsModalWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		color: black;
		overflow: visible;
		background: transparent;
	}

	.MuiDialog-paperWidthSm {
		max-width: 900px;
	}

	.box-modal {
		width: 920px;
		height: 705px;
		display: flex;
		padding-top: 50px;
		position: relative;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.MATH.MATHAHTZEE.QUESTION_MODAL});

		${responsive.DISPLAY`
			width: 828px;
    		height: 635px;
		`}

		${responsive.DESKTOP`
			width: 526px;
    		height: 415px;
			padding-top: 12px;
		`}

		.timer-text {
			top: 24px;
			color: white;
			font-size: 20px;
			position: absolute;
			left: calc(50% - 41px);

			${responsive.DESKTOP`
				top: 10px;
				font-size: 16px;
				left: calc(50% - 34px);
			`}
		}

		.logo {
			width: 100%;
			height: 210px;
			margin-top: 40px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(${IMAGES.MATH.MATHAHTZEE.LETS_PLAY});

			${responsive.DISPLAY`
				height: 150px;
				margin-top: 0px;
			`}

			${responsive.DESKTOP`
				height: 95px;
				margin-top: 0px;
			`}
		}

		.question-text {
			color: #fff;
			font-size: 30px;
			text-align: center;
			margin: ${(props) =>
				props.isFinal ? "0px" : props.type === QUESTION_TYPES.TEXT_FIELD ? "30px 30px 0px" : props.type === QUESTION_TYPES.RANKING_CHOICE ? "20px 30px 20px" : "30px 30px 0px"};

			${responsive.DISPLAY`
				font-size: 26px;
			`}

			${responsive.DESKTOP`
				font-size: 16px;
				margin: 20px 20px 0px;
			`}
		}

		.helper-text {
			color: #fff;
			font-size: 18px;
			text-align: center;

			${responsive.DESKTOP`
				font-size: 14px;
			`}
		}

		.text-field-container {
			width: 80%;
			margin: 30px 0px;

			.text-field {
				border: solid 1px #fff;

				.MuiInputBase-root {
					color: #fff;
					background-color: #2a1002;
				}

				fieldset {
					border: none;
				}
			}
		}

		.option-container {
			width: 100%;
			display: flex;
			gap: 20px 40px;
			flex-wrap: wrap;
			padding: 10px 70px;
			margin-bottom: 20px;
			align-items: center;
			justify-content: space-between;

			${responsive.DESKTOP`
				gap: 15px 30px;
			`}

			.option {
				width: 45%;
				color: white;
				height: 50px;
				display: flex;
				cursor: pointer;
				text-align: center;
				border-radius: 5px;
				align-items: center;
				justify-content: center;
				background-size: 100% 100%;
				background-position: center;
				background-repeat: no-repeat;
				box-shadow: 0px 0px 5px 0px #8a8a8a;
				background-image: url(${IMAGES.MATH.MATHAHTZEE.OPTION});

				${responsive.DESKTOP`
					height: 30px;
					font-size: 12px;
				`}

				&.disabled {
					cursor: context-menu;
				}

				&.selected {
					background-image: url(${IMAGES.MATH.MATHAHTZEE.CORRECT_OPTION});
				}

				span {
					width: 90%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		.ranking-container {
			gap: 10px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			padding: 10px 70px;
			margin-bottom: 10px;
			align-items: center;
			flex-direction: column;

			div {
				color: white;
				min-width: 45%;
				cursor: pointer;
				padding: 8px 15px;
				user-select: none;
				text-align: center;
				border: solid 1px #fff;
				background-color: #2a1002;
			}

			.is-dragging {
				opacity: 0.5;
				border-color: ${COLORS.SECONDARY};
			}
		}

		.btn-container {
			width: ${(props) => (props.isFinal ? "250px" : "400px")};
			padding: 4px;
			border-radius: 10px;
			margin: ${(props) => (props.isFinal ? "40px auto 0px" : "0px auto 0px")};
			background: transparent;
			transition: opacity 0.3s ease-in-out;
			animation: rounded 20s linear infinite;

			.done-btn {
				z-index: 1;
				width: 100%;
				color: #fff;
				height: 45px;
				filter: blur(2px);
				padding: 5px 40px;
				border-radius: 10px;
				background-size: 400%;
				letter-spacing: 1.6px;
				text-shadow: 0.3px 2px 0 #041727;
				box-shadow: -0.4px 5px 0 0 #085264;
				transition: opacity 0.3s ease-in-out;
				font-size: ${(props) => (props.isFinal ? "25px" : "28px")};
				background: linear-gradient(to top, #83ebdf, #3491b4, #80ebe1);

				${responsive.DISPLAY`
					height: 40px;
					font-size: 24px;
				`}

				${responsive.DESKTOP`
					height: 30px;
    				font-size: 16px;
				`}

				&.enable {
					cursor: pointer;
					filter: blur(0px);
				}
			}
		}

		.progress {
			margin-right: 10px;
			width: 30px !important;
			height: 30px !important;
			color: #3c270f !important;
		}
	}
`;

export const DialogWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		color: black;
		overflow: visible;
		background: transparent;
	}

	.MuiDialog-paperWidthSm {
		max-width: 900px;
	}

	.box-modal {
		width: 750px;
		height: 600px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.MATH.MATHAHTZEE.HOW_TO_PLAY});

		${responsive.DISPLAY`
			width: 700px;
    		height: 560px;
		`}

		${responsive.DESKTOP`
			width: 640px;
   			height: 510px;
		`}

		.title-container {
			.sub-title {
				color: #fff;
				font-size: 35px;
				margin-top: 20px;

				${responsive.DISPLAY`
					font-size: 32px;
    				margin-top: 22px;
				`}

				${responsive.DESKTOP`
					font-size: 26px;
    				margin-top: 20px;
				`}
			}

			.icon {
				top: 28px;
				z-index: 3;
				right: 35px;
				width: 30px;
				height: 30px;
				cursor: pointer;
				position: absolute;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url(${IMAGES.MATH.MATHAHTZEE.CROSS});

				${responsive.DISPLAY`
					top: 22px;
				`}

				${responsive.DESKTOP`
					top: 20px;
				`}
			}
		}

		.content-wrapper {
			padding: 15px;
			height: 420px;
			padding-top: 25px;
			position: relative;

			${responsive.DISPLAY`
				height: 390px;
			`}

			${responsive.DESKTOP`
				height: 358px;
			`}

			.scroll-bar {
				.thumb-vertical {
					margin-left: -25px;
					background-color: #3c3c3c;
				}
			}

			.play-img-container {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;

				.img {
					width: 580px;
					margin-top: 40px;
					object-fit: contain;
				}
			}

			.content {
				width: 76%;
				color: #fff;
				margin: 0px auto 0px;
			}

			.attach-file {
				display: flex;
				justify-content: center;

				.icon {
					color: #fff;
				}

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
					color: #fff;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #275e00;
					margin-top: 1px;
				}
			}

			.progress {
				top: 36%;
				left: 0;
				right: 0;
				color: #000;
				position: absolute;
			}

			.type-switcher {
				.divider {
					width: 50%;
					height: 2px;
					opacity: 0.5;
					margin: 5px auto;
					background-color: #fff;
				}

				.text {
					color: #fff;
					cursor: pointer;
					transition: 0.3s;
					margin: 0px 0px 22px 20px;
				}
			}
		}

		.btn-container {
			width: 400px;
			padding: 4px;
			border-radius: 10px;
			margin: 15px auto 0px;
			background: transparent;
			transition: opacity 0.3s ease-in-out;
			animation: rounded 20s linear infinite;

			${responsive.DISPLAY`
				width: 365px;
				margin: 18px auto 0px;
			`}

			${responsive.DESKTOP`
				width: 325px;
				margin: 20px auto 0px;
			`}

			.done-btn {
				z-index: 1;
				width: 100%;
				color: #fff;
				font-size: 28px;
				filter: blur(2px);
				padding: 5px 40px;
				border-radius: 10px;
				background-size: 400%;
				letter-spacing: 1.6px;
				text-shadow: 0.3px 2px 0 #041727;
				box-shadow: -0.4px 5px 0 0 #085264;
				transition: opacity 0.3s ease-in-out;
				background: linear-gradient(to top, #83ebdf, #3491b4, #80ebe1);

				${responsive.DISPLAY`
					font-size: 24px;
				`}

				${responsive.DESKTOP`
					font-size: 20px;
				`}

				&.enable {
					cursor: pointer;
					filter: blur(0px);
				}
			}
		}
	}
`;
