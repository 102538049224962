import React, { Fragment, useEffect, useState } from "react";
import useSound from "use-sound";
import { Tooltip, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateTotalScore, schoolClassTestID } from "Redux/Auth/Actions";
import { quizSound, showToast } from "Redux/General/Actions";
// COMPONENTS
import LazyImage from "Components/Commons/LazyImage/LazyImage";
import HowToPlayConnect4 from "Components/Maths/Connect4/Modal/HowToPlay";
import HowToPlayMathahtzee from "Components/Maths/Mathahtzee/Modal/HowToPlay";
// STYLES
import { PageWrapper, GridContainer, ScoreboardWrapper, ProgressWrapper } from "./Dashboard.style";
// UTILS
import { random, Api, URL_CONNECT4, MATHS_MODULE_ID, URL_MATHAHTZEE, IMAGES } from "Helpers";
// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";

const tooltipStyles = { tooltip: { padding: "8px 18px", borderRadius: "10px", marginBottom: "22px", fontSize: "14px", backgroundColor: "white", color: "black" } };

const sectionConst = [
	{ value: 0, isLive: true, name: "Connect 4", url: URL_CONNECT4, image: IMAGES.MATH.CONNECT4.PLAY_LOGO, id: MATHS_MODULE_ID.CONNECT4, isTestAvailable: false },
	{ value: 0, isLive: true, name: "Mathahtzee", url: URL_MATHAHTZEE, image: IMAGES.MATH.MATHAHTZEE.LETS_PLAY, id: MATHS_MODULE_ID.MATHAHTZEE, isTestAvailable: false },
];

const Dashboard = () => {
	const dispatch = useDispatch();
	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const { gamesStatus } = useSelector((state) => state.GamesData);
	const subjects = useSelector((state) => state.Auth.subjectAccessList);

	const subjectId = subjects?.filter((item) => item.subject === "Math")[0].subjectId;

	const [sections, setSections] = useState([...sectionConst]);
	const [scoreboard, setScoreboard] = useState([]);
	const [isStudent, setStudent] = useState(false);
	const [testAvailable, setTestStatus] = useState(false);
	const [status, setStatus] = useState(0);
	const [loading, setLoading] = useState(true);
	const [connect4HowToPlay, setConnect4HowToPlay] = useState({ open: false, url: null, id: null });
	const [mathahtzeeHowToPlay, setMathahtzee4HowToPlay] = useState({ open: false, url: null, id: null });
	const [schoolClassTestId, setSchoolClassTestId] = useState("");

	useEffect(() => {
		dispatch(quizSound(true));

		setTimeout(() => {
			const newSections = [...sections];

			newSections.forEach((section) => {
				if (!section.isLive) section.value = random(30, 100);
			});

			setSections(newSections);
		}, 1500);
	}, []); // eslint-disable-line

	useEffect(() => {
		if (Object.keys(gamesStatus).length) getStatus();
	}, [gamesStatus]); // eslint-disable-line

	const getStatus = async () => {
		try {
			setLoading(true);

			let response = await new Api().get("general/studentStatus", { params: { subjectId: subjectId } });

			const percentValue = (sub, total) => ((sub * 100) / total).toFixed(0);

			// SET PROGRESS
			const newValues = [...sections];

			newValues.forEach((section) => {
				switch (section.id) {
					case MATHS_MODULE_ID.CONNECT4:
						if (response?.data?.connect4?.isConnect4Complete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.connect4?.totalRightAnswerConnect4, response?.data?.connect4?.totalQuestionConnect4);
							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isConnect4Active) section.isTestAvailable = true;

						break;

					case MATHS_MODULE_ID.MATHAHTZEE:
						if (response?.data?.mathahtzee?.isMathahtzeeComplete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.mathahtzee?.totalRightAnswerMathahtzee || 0, response?.data?.mathahtzee?.totalQuestionMathahtzee);
							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isMathahtzeeActive) section.isTestAvailable = true;

						break;

					default:
						section.value = 0;

						break;
				}
			});

			dispatch(updateTotalScore(response.data.total));
			dispatch(schoolClassTestID(response?.data?.schoolClassTestId));

			setSections(newValues);
			setScoreboard(response.data.scoreDashBoardList);
			setTestStatus(response?.data?.isLiveTestAvailable);
			setStudent(response?.data?.isStudentLogin);
			setStatus(response.data.status);
			setSchoolClassTestId(response?.data?.schoolClassTestId);

			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const toggleHowToPlay = (section) => {
		if (!testAvailable && isStudent) {
			dispatch(showToast("Test is not available. Try after some time or contact to your respective class teacher | school."));
			return;
		}

		if (status !== 0) {
			let setStateFunction;

			if (section.url === URL_CONNECT4) setStateFunction = setConnect4HowToPlay;
			if (section.url === URL_MATHAHTZEE) setStateFunction = setMathahtzee4HowToPlay;

			if (setStateFunction)
				setStateFunction((prev) => {
					const copyPrev = { ...prev };

					if (!copyPrev.open) {
						copyPrev.url = section.url;
						copyPrev.id = section.id;
					}

					copyPrev.open = !copyPrev.open;

					return copyPrev;
				});
		} else dispatch(showToast("No Active Tests found."));
	};

	const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

	const gameListUi = () => {
		return (
			<GridContainer className="flex f-v-center f-h-center overlay-effect">
				{sections.map((section) => (
					<Fragment key={section.name}>
						{section.isLive ? (
							<div className="box">
								<div className="box-content" onClick={() => !loading && section.isTestAvailable && toggleHowToPlay(section)}>
									<div className={`img-container ${!section.isTestAvailable && "disabled"}`} onMouseOver={play}>
										<div className="title">{section.name}</div>

										<div className="game-logo" style={{ backgroundImage: `url(${section.image})` }}></div>
										<div className="progress-container full-width" onMouseOver={(e) => e.preventDefault()}>
											<Progress value={section.value} />
										</div>
									</div>
								</div>
							</div>
						) : (
							<CustomTooltip title="Coming soon!!!" placement="top">
								<div className="box">
									<div className={`box-content `}>
										<div className="img-container" onMouseOver={play}>
											<div className="title">{section.name}</div>
										</div>
									</div>
								</div>
							</CustomTooltip>
						)}
					</Fragment>
				))}
			</GridContainer>
		);
	};

	return (
		<PageWrapper>
			<LazyImage compressedImage={IMAGES.MATH.COMPRESSED_COMMON_BACKGROUND} image={IMAGES.MATH.COMMON_BACKGROUND} wrapperProps={{ className: "background-image" }} />

			{loading && (
				<div className="overlay-stackrank">
					<CircularProgress className="progress" />
				</div>
			)}

			<div className="title-history"></div>

			<div className="content slider">{gameListUi()}</div>

			<Scoreboard peoples={scoreboard} />

			{connect4HowToPlay.open && (
				<HowToPlayConnect4 open={connect4HowToPlay.open} handleClose={toggleHowToPlay} data={connect4HowToPlay} subjectId={subjectId} schoolClassTestId={schoolClassTestId} />
			)}

			{mathahtzeeHowToPlay.open && <HowToPlayMathahtzee open={mathahtzeeHowToPlay.open} handleClose={toggleHowToPlay} data={mathahtzeeHowToPlay} subjectId={subjectId} />}
		</PageWrapper>
	);
};

const Progress = (props) => (
	<ProgressWrapper {...props}>
		<div className="outer">
			<div className="active" />
		</div>
		<div className="text">{props.value}%</div>
	</ProgressWrapper>
);

const Scoreboard = (props) => (
	<ScoreboardWrapper>
		<div className="people-cards">
			{props?.peoples?.map((person) => (
				<div className="person flex f-v-center f-h-center" key={person.winnerPlace}>
					<div className="content text-center">
						<div className="image" style={{ backgroundImage: `url(${person.profileImage})` }}></div>
						<div className="name elipsis">{person.firstName}</div>
						<div className="score">{person.totalScore}</div>
					</div>
				</div>
			))}
		</div>
	</ScoreboardWrapper>
);

export default Dashboard;
